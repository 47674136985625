$stacking-width: 750px;
$mobile-width: 420px;
$container-responsive: 1280px;

.App {
  background: var(--pale-grey);
  min-height: 100vh;
  padding: var(--global-padding) 0;
  //Variables
  --container-width: 1200px;
  --global-padding: 40px;
  --dark-grey: rgba(99, 110, 114, 0.75);
  --opaque-dark-grey: rgba(99, 110, 114, 0.25);
  --stat-row-border: rgba(99, 110, 114, 0.25);
  --light-grey: #B2BEC3;
  --pale-grey: #DFE6E9;
  --pale-blue: #4f8fa5;
  --hover-opacity: 0.75;

  .graph-container {
    border: 1px solid #000;
    flex: 1 0 0px;
  }
}

body {
  font-size: 16px;
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;

  @media(max-width: $container-responsive) {
    max-width: 100vw;
    padding: 0 var(--global-padding);
  }

  @media(max-width: $stacking-width) {
    padding: 0 calc(0.75 * var(--global-padding))
  }

  @media(max-width: $mobile-width) {
    padding: 0 calc(0.75 * var(--global-padding))
  }
}

* {
  box-sizing: border-box;
}

//Graph section
section.network-graph-section {
  display: flex;
  align-items: center;

  @media(max-width: $stacking-width) {
    flex-direction: column-reverse;
    
    .filter-container {
      max-width: unset;
      margin-left: unset;
      margin-bottom: calc(0.5 * var(--global-padding));
    }

  }
}

//Network Graph
.graph-container { 
  position: relative;
  overflow: hidden;

  .vis-network .vis-navigation {
    .vis-button {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: opacity 0.5s ease-in-out;

      &:hover {
        box-shadow: unset;
        opacity: var(--hover-opacity);
      }

      &.vis-up {
        background-image: url('/images/up-arrow.svg');
      }

      &.vis-down {
        background-image: url('/images/down-arrow.svg');
      }

      &.vis-left {
        background-image: url('/images/left-arrow.svg');
      }

      &.vis-right {
        background-image: url('/images/right-arrow.svg');
      }

      &.vis-zoomIn {
        background-image: url('/images/zoom-in.svg');
      }

      &.vis-zoomOut {
        background-image: url('/images/zoom-out.svg');
      }

      &.vis-zoomExtends {
        background-image: url('/images/fullscreen.svg');
      }
    }
  }

  .network-legend {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border: 1px solid var(--dark-grey);
    border-bottom-right-radius: 6px;
    transition: transform 0.5s ease-in-out;

    .legend-container {
      padding: 10px;
    }

    h2 {
      font-size: 1em;
      margin-block-start: 0;
      margin-block-end: 5px;
    }

    h4 {
      font-size: 0.875em;
      margin-block-end: 5px;
      margin-block-start: 5px;

      &:first-child {
        margin-block-start: unset;
      }
    }
    .legend-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        margin-right: 5px;
      }

      span {
        font-size: 0.75em;
      }
    }

    #legend-toggle {
      position: absolute;
      top: 8px;
      right: 0px;
      height: 24px;
      width: 24px;
      background-image: url('./images/legend-arrow.svg');
      background-size: cover;
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
      cursor: pointer;
      transform: rotateY(180deg);

      &:hover {
        opacity: 0.6;
      }
    }

    &.hidden {
      transform: translateX(-100%);

      #legend-toggle {
        transform: translateX(24px);
      }
    }
  }
}

//Network Filter
.filter-container {
  max-width: 300px;
  width: 100%;
  height: 100%;
  margin-left: 1em;

  .filter-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;

    h3 {
      display: inline-block;
      margin-block-start: unset;
      margin-block-end: unset;
    }
  }

  .filter-wrapper {
    padding: calc(0.5 * var(--global-padding));
    border: 1px solid var(--dark-grey);
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }

    &.individual-coach-filter {

      .filter-heading {
        margin-bottom: 1.5em;
      }

      select, button {
        display: block;
        width: 100%;
        margin-bottom: 1em;
        text-align: center;

        &:last-child {
          margin-bottom: unset;
        }
      }
    }
  }

  .filter-sub-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-information {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;

    p {
      display: inline-block;
    }
  }

  .filter-reset {
    border: 1px solid var(--dark-grey);
    color: black;;
    background: var(--pale-grey);
    border-radius: 4px;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;

    &:hover {
      opacity: var(--hover-opacity);
    }
  }

  .radio-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;


    input {
      margin-right: 3px;
      margin-top: 0;
      cursor: pointer;
    }
  }

  .min-max-filter-wrapper {
    display: flex;
    align-items: center;

    .input-wrapper {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      
      &:first-child {
        margin-right: 0.5em;
      }

      &:last-child { 
        margin-left: 0.5em;
      }
    }

    &.xpa-filter-wrapper {
      flex-wrap: wrap;
      
      .input-wrapper {
        &:first-child {
          margin-right: 0;
          margin-bottom: 0.5em;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}

//Project Content
.content-container {
  text-align: center;
  padding: 0 var(--global-padding);
  margin-bottom: var(--global-padding);

  .project-description {
    text-align: left;
  }
}

//Individual Coaching
.individual-coach-stat {
  background: var(--dark-grey);
  padding: calc(0.5 * var(--global-padding));
  margin: 1em 0;

  &:last-child {
    margin-bottom: unset;
  }

  .container {
    background: var(--light-grey);
  }

  .heading-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;

    .coach-close {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: opacity 0.5s ease-in-out;

      &:hover {
        opacity: var(--hover-opacity);
      }
    }

    h4 {
      margin-block-end: 0.5em;
      margin-block-start: 0.5em;
    }
  }

  .coach-information {
    p {
      margin-block-end: 0.25em;
      margin-block-start: 0.25em;
      text-align: center;
    }
  }

  .tabs-wrap {
    padding: calc(0.5* var(--global-padding));
    padding-bottom: 0;

    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-inline-start: 0;

      li {
        flex: 1 0 0px;
        text-align: center;
        background: var(--pale-grey);
        border-right: 1px solid var(--dark-grey);
        padding: 6px 12px;
        cursor: pointer;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        list-style: none;

        &:first-child {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }

        &:last-child {
          border-right: 0px;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }

        &:hover, &.active {
          background: var(--pale-blue);
	        color: #fff;
        }
      }
    }
  }

  .stat-wrapper {
    padding: calc(0.5 * var(--global-padding));
    overflow: hidden;
    --min-table-width: 1000px;
  }

  .stat-table {
    overflow-x: auto;
    max-width: 100%;
    padding-bottom: 10px;
  }

  .stat-heading {
    display: flex;
    padding-bottom: 0.5em;
    border-bottom: 2px solid var(--dark-grey);
    min-width: var(--min-table-width);

    span {
      font-weight: bold;
      flex: 1 0 0px;
      padding: 0 0.125em;
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-wrap: nowrap;
    }
  }

  .stat-row {
    display: flex;
    padding: 0.125em 0;
    align-items: center;
    border-bottom: 1px solid var(--stat-row-border);
    min-width: var(--min-table-width);

    span {
      flex: 1 0 0px;
      padding: 0 0.125em;

      &.no-record {
        flex: 2 0 0px;
      }
    }

  }

  .performance-heading {
    display: flex;
    padding-bottom: 0.5em;
    border-bottom: 2px solid var(--dark-grey);

    span {
      flex: 1 0 0;
      font-weight: bold;
    }
  }

  .stat-colors {
    h6 {
      margin-block-start: 0.25em;
      margin-block-end: 0.25em;
    }

    .colors {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      i {
        display: inline-flex;
        align-items: center;
        margin-right: 0.5em;
      }

      .item {
        margin-right: 1em;
        display: inline-flex;
        align-items: center;

        span {
          display: inline-flex;
          align-items: center;
        }
      }
    }
  }

  .performance-row {
    display: flex;

    span {
      flex: 1 0 0px;
    }
  }

  .toggle-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .show-head-coach-only-stats {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .coaching-connections {
    border-top: 1px solid var(--dark-grey);
    margin-top: 1em;
    padding-top: 1em;

    .stat-heading {
      border-bottom: unset;
      margin-bottom: 0.5em;
    }

    .related-connections {
      display: flex;
      flex-wrap: wrap;

      span:not(.no-related-coaches) {
        flex: 1 0 33%;
        margin-top: 0.25em; 

        strong {
          transition: opacity 0.5s ease-in-out;
          cursor: pointer;

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }

    .no-related-coaches {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;

    }
  }
}

//Tooltip
.tooltip-notice {
  position: absolute;
  max-width: 300px;
  width: auto;
  background: #fff;
  border: 2px solid #000;
  padding: 0.5em;
  border-radius: 12px;
  z-index: 999999; 
  
  .rc-tooltip-inner {
    color: rgb(0,0,0);
    text-align: center;
    font-size: 0.825em;
  }
}


.information-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;

  &:hover {
    opacity: var(--hover-opacity);
  }

  svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

//Modal
.modal-overlay {
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  &.overlay-size-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &.overlay-size-viewbox {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
  }
}
